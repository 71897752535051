<template>
  <div class="mainwidth">
    <div class="box">
      <div>跳转失败</div>
      <p>账号类型非法人/企业 无法跳转至企业认证</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    error(){
        const mssg = this.$route.query.mssg;
        const a = decodeURIComponent(mssg)
        this.$message.error(a)
      }
  },
  mounted(){
    this.error()
  }
};
</script>

<style lang="less" scoped>
.box {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px 10px;
  background-color: @color-ff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #929292;
  position: relative;
  font-size: @font-xl;
  text-align: center;
  p {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
